import { Injectable } from '@angular/core';
import { BizHttp } from '../../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../../framework/core/http/http';
import { EmployeeTaxCard, TaxCard, FreeAmountType, CompanySalary } from '../../../unientities';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { CompanySalaryService } from '../companySalary/companySalaryService';

@Injectable({ providedIn: 'root' })
export class EmployeeTaxCardService extends BizHttp<EmployeeTaxCard> {
    private freeAmountTypes = [
        { id: FreeAmountType.None, name: 'Ingen' },
        { id: FreeAmountType.WithAmount, name: 'Frikort med beløp' },
        { id: FreeAmountType.NoLimit, name: 'Frikort uten beløp' },
    ];
    private taxCardResutStatus = [
        { id: 0, statusname: 'ikkeSkattekort', name: 'Ikke skattekort' },
        { id: 1, statusname: 'vurderArbeidstillatelse', name: 'Vurder arbeidstillatelse' },
        { id: 3, statusname: 'ikkeTrekkplikt', name: 'Ikke trekkplikt' },
        { id: 1, statusname: 'skattekortopplysningerOK', name: 'Skattekort OK' },
        { id: 1, statusname: 'ugyldigOrganisasjonsnummer', name: 'Ugyldig orgnr' },
        { id: 1, statusname: 'ugyldigFoedselsEllerDnummer', name: 'Ugyldig f-nr/d-nr' },
        {
            id: 1,
            statusname: 'utgaattDnummerSkattekortForFoedselsnummerErLevert',
            name: 'Utgått d-nr, skattekort for f-nr er levert',
        },
    ];

    constructor(
        protected http: UniHttp,
        private yearService: FinancialYearService,
        private companySalaryService: CompanySalaryService,
    ) {
        super(http);
        this.relativeURL = EmployeeTaxCard.RelativeUrl;
        this.entityType = EmployeeTaxCard.EntityType;
    }

    public expandOptionsNewTaxcardEntity: Array<string> = [
        'loennFraHovedarbeidsgiver',
        'loennFraBiarbeidsgiver',
        'pensjon',
        'loennTilUtenrikstjenestemann',
        'loennKunTrygdeavgiftTilUtenlandskBorger',
        'loennKunTrygdeavgiftTilUtenlandskBorgerSomGrensegjenger',
        'ufoereYtelserAndre',
    ];
    getTaxCardResutStatusName(statusname: string): string {
        return this.taxCardResutStatus.find((x) => x.statusname === statusname)?.name ?? statusname;
    }

    public get(employeeID: number): Observable<EmployeeTaxCard> {
        const year = this.yearService.getActiveYear();
        return this.GetAll(
            `filter=Year eq ${year} and EmployeeID eq ${employeeID}`,
            this.expandOptionsNewTaxcardEntity,
        ).pipe(
            map((result) => result[0]),
            map((result: EmployeeTaxCard) => {
                if (result?.loennFraBiarbeidsgiver && result.loennFraBiarbeidsgiver?.AntallMaanederForTrekk == 0) {
                    result.loennFraBiarbeidsgiver.AntallMaanederForTrekk = 10.5;
                }
                return result;
            }),
            switchMap((taxCard: EmployeeTaxCard) => (taxCard ? of(taxCard) : this.getNewTaxCard(employeeID, year))),
            map(
                (card) =>
                    <EmployeeTaxCard>{
                        ...card,
                        _lastUpdated: card.UpdatedAt || card.CreatedAt,
                    },
            ),
        );
    }

    public save(taxCard: EmployeeTaxCard): Observable<EmployeeTaxCard> {
        if (!taxCard) {
            return of(null);
        }
        return taxCard.ID ? super.Put(taxCard.ID, taxCard) : super.Post(taxCard);
    }

    public getNameFromFreeAmountType(freeAmountType: FreeAmountType) {
        const type = this.freeAmountTypes.find((t) => t.id === freeAmountType);
        return (type && type.name) || this.freeAmountTypes[0].name;
    }

    public taxExpands(): string {
        return this.expandOptionsNewTaxcardEntity.toString();
    }

    public getTaxCardPercentAndTable(
        taxCard: EmployeeTaxCard,
        year = taxCard && taxCard.Year,
    ): { percent: string; table: string } {
        if (year <= 2017) {
            return this.formatTaxCardInfo(this.getTaxCardPercentAndTable2017(taxCard));
        }

        return this.formatTaxCardInfo(this.getTaxCardPercentAndTableFrom2018(taxCard));
    }

    private formatTaxCardInfo(taxInfo: { percent: string; table: string }): { percent: string; table: string } {
        return {
            percent: taxInfo.percent ? `(${taxInfo.percent}%)` : '',
            table: taxInfo.table ? `(${taxInfo.table})` : '',
        };
    }

    private getTaxCardPercentAndTable2017(taxCard: EmployeeTaxCard): { percent: string; table: string } {
        if (!taxCard) {
            return {
                percent: '50',
                table: '',
            };
        }
        return {
            percent: `${taxCard.Percent || ''}` || (taxCard.Table ? '' : '50'),
            table: taxCard.Table || '',
        };
    }

    private getTaxCardPercentAndTableFrom2018(taxCard: EmployeeTaxCard): { percent: string; table: string } {
        if (!taxCard) {
            return {
                percent: '',
                table: '',
            };
        }

        const taxCards = [
            ...this.addTaxCard(
                taxCard.NotMainEmployer ? taxCard.loennFraBiarbeidsgiver : taxCard.loennFraHovedarbeidsgiver,
            ),
            ...this.addTaxCard(taxCard.loennKunTrygdeavgiftTilUtenlandskBorger),
            ...this.addTaxCard(taxCard.loennKunTrygdeavgiftTilUtenlandskBorgerSomGrensegjenger),
            ...this.addTaxCard(taxCard.loennTilUtenrikstjenestemann),
            ...this.addTaxCard(taxCard.pensjon),
        ];

        if (!taxCards.length || taxCards.length > 1) {
            return {
                percent: '',
                table: '',
            };
        }

        return {
            percent: `${taxCards[0].Percent || ''}`,
            table: taxCards[0].Table || '',
        };
    }

    private addTaxCard(taxCard: TaxCard): TaxCard[] {
        if (!taxCard) {
            return [];
        }
        return taxCard.Table || taxCard.Percent ? [taxCard] : [];
    }

    private getNewTaxCard(employeeID: number, year: number): Observable<EmployeeTaxCard> {
        return this.companySalaryService.getCompanySalary().pipe(
            map((companySalary: CompanySalary) => companySalary.KeepNotMainEmployerOnTaxCard),
            switchMap((keepNotMainEmployerOnTaxCard) => {
                if (keepNotMainEmployerOnTaxCard) {
                    //Check previous year value
                    return this.secondaryEmployerLastYear(employeeID, year - 1);
                } else return of(false);
            }),
            switchMap((notMainEmployer) => {
                return super.GetNewEntity(this.expandOptionsNewTaxcardEntity).pipe(
                    map((taxCard: EmployeeTaxCard) => ({
                        ...taxCard,
                        NotMainEmployer: notMainEmployer,
                        ResultatStatus: null,
                        Year: year,
                        EmployeeID: employeeID,
                    })),
                );
            }),
        );
    }

    private secondaryEmployerLastYear(employeeID: number, previousYear: number): Observable<boolean> {
        return this.GetAll(`filter=Year eq ${previousYear} and EmployeeID eq ${employeeID}`).pipe(
            map((result) => (result[0] ? result[0].NotMainEmployer : false)),
        );
    }
}
